import {useEffect, useState} from 'react'
import Header from '../../layout/Header'
import Footer from '../../layout/Footer'

import {  useNavigate } from 'react-router-dom'
// import AddBox from "@material-ui/icons/AddBox";
// import ArrowDownward from "@material-ui/icons/ArrowDownward";
// import Check from "@material-ui/icons/Check";
// import ChevronLeft from "@material-ui/icons/ChevronLeft";
// import ChevronRight from "@material-ui/icons/ChevronRight";
// import Clear from "@material-ui/icons/Clear";
// import DeleteOutline from "@material-ui/icons/DeleteOutline";
// import Edit from "@material-ui/icons/Edit";
// import FilterList from "@material-ui/icons/FilterList";
// import FirstPage from "@material-ui/icons/FirstPage";
// import LastPage from "@material-ui/icons/LastPage";
// import Remove from "@material-ui/icons/Remove";
// import SaveAlt from "@material-ui/icons/SaveAlt";
// import Search from "@material-ui/icons/Search";
// import ViewColumn from "@material-ui/icons/ViewColumn";

import BaseUrl from '../../baseurl/BaseUrl';
import { Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
// import { Input } from '@material-ui/core';
import { PostApi, getApiWithToken } from "../../Helper/helper";
import Swal from "sweetalert2";

const Addhome =()=> {
  const [loader, setLoader] = useState(false);
  const navigate = new useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/Login");
    } else {
      // setShow2(true);
    }
    window.scrollTo(0, 0);
  
    getData(); // Assuming getData is a function defined outside useEffect
    // getDatas();
    // statusll("pending")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
    //   const nextpage =(id)=>{
    //     console.log(id, 'll')
    //     navigate(`/parent-details?id=${id}`);
    
    
    
    
    
    //   }
    // const tableIcons = {
    //     Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    //     Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    //     Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    //     DeleteOutline: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    //     DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    //     Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    //     Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    //     Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    //     FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    //     LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    //     NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    //     PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    //     ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    //     Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    //     SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    //     ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    //     ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    //   };
//   const [, setdatas] = useState();
  const [userData,] = useState(localStorage.getItem("token"));
    const getData = () => {
      setLoader(true)
      getApiWithToken(`${BaseUrl.baseUrl}admin/home`, "", userData)
        .then(({ data }) => {
          console.log("minhaj", data.home);
          console.log("minhaj", data);

        //   setdatas(data.home)

          setfirst_detail(data.home?.first_detail)
          setsecond_detail(data.home?.second_detail)
          setthird_detail(data.home?.third_detail)
          setfour_detail(data.home?.four_detail)
          setfifth_detail(data.home?.fifth_detail)
          setphone(data.home?.phone)
          setaddress(data.home?.address)
          setlogo(data.home?.logo)
          setBannerimg(data.home?.header_image)
          setimgcard1(data.home_slider?.image_1)
          setimgcard2(data.home_slider?.image_2)
          setimgcard3(data.home_slider?.image_3)
          setimgcard4(data.home_slider?.image_4)
          setimgcard7(data.make_appointment?.image)
          setimgcard8(data.news_letter?.image)
          settitle_1(data.home_slider?.title_1)
          settitle_2(data.home_slider?.title_2)
          settitle_3(data.home_slider?.title_3)
          settitle_4(data.home_slider?.title_4)
          setdescription_1(data.home_slider?.description_1)
          setdescription_2(data.home_slider?.description_2)
          setdescription_3(data.home_slider?.description_3)
          setdescription_4(data.home_slider?.description_4)
          setdescription_7(data.make_appointment?.description)
          setEmail(data.make_appointment?.email)
          setdescription_8(data.news_letter?.description)
          settitle_7(data.make_appointment?.title)
          settitle_8(data.news_letter?.title)
          




          

          


          setfirst_title(data.home?.first_title)
          setsecond_title(data.home?.second_title)
          setthird_title(data.home?.third_title)
          setfour_title(data.home?.four_title)
          setfifth_title(data.home?.fifth_title)


           
          
          setLoader(false)
        })
        .catch((err) => {
          console.log("err---------", err);
        });
    };
    const [first_detail, setfirst_detail]= useState()
    const [second_detail, setsecond_detail]= useState()
    const [third_detail, setthird_detail]= useState()
    const [four_detail, setfour_detail]= useState()
    const [fifth_detail, setfifth_detail]= useState()
    const [phone, setphone]= useState("")
    const [address, setaddress]= useState("")
    const [logo, setlogo]= useState()
    const [Bannerimg, setBannerimg]= useState()
    console.log(Bannerimg);

    
    const [first_title, setfirst_title]= useState()
    const [second_title, setsecond_title]= useState()
    const [third_title, setthird_title]= useState()
    const [four_title, setfour_title]= useState()
    const [fifth_title, setfifth_title]= useState()
    const [imgcard1,setimgcard1]=useState()
    const [imgcard2,setimgcard2]=useState()
    const [imgcard3,setimgcard3]=useState()
    const [imgcard4,setimgcard4]=useState()
    // const [imgcard5,setimgcard5]=useState()
    // const [imgcard6,setimgcard6]=useState()
    const [imgcard7,setimgcard7]=useState()
    const [imgcard8,setimgcard8]=useState()

    const [title_1,settitle_1]=useState()
    const [title_2,settitle_2]=useState()
    const [title_3,settitle_3]=useState()
    const [title_4,settitle_4]=useState()
    const [description_1,setdescription_1]=useState()
    const [description_2,setdescription_2]=useState()
    const [description_3,setdescription_3]=useState()
    const [description_4,setdescription_4]=useState()
    const [description_7,setdescription_7]=useState()
    const [description_8,setdescription_8]=useState()
    const [title_7,settitle_7]=useState()
    const [title_8,settitle_8]=useState()
    const [Email,setEmail]=useState()




    
    


    
    const onSubmit = () => {
        // setloader(true)
        // console.log('rewre============w')
        const formData = new FormData();
        formData.append("first_detail", first_detail);
        formData.append("second_detail", second_detail);
        formData.append("third_detail", third_detail);
        formData.append("four_detail", four_detail);
        formData.append("fifth_detail", fifth_detail);
        formData.append("phone", phone);
        formData.append("address", address);
        formData.append("logo", logo);
        formData.append("header_image",Bannerimg);
        formData.append("image_1", imgcard1);
        formData.append("image_2", imgcard2);
        formData.append("image_3", imgcard3);
        formData.append("image_4", imgcard4);
        // formData.append("image_4", imgcard4);
        formData.append("appointment_image", imgcard7);
        formData.append("newsletter_image", imgcard8);
        formData.append("title_1", title_1);
        formData.append("title_2", title_2);
        formData.append("title_3", title_3);
        formData.append("title_4", title_4);
        formData.append("description_1",description_1);
        formData.append("description_2",description_2);
        formData.append("description_3",description_3);
        formData.append("description_4",description_4);
        formData.append("appointment_description",description_7);
        formData.append("newsletter_description",description_8);
        formData.append("appointment_title",title_7);
        formData.append("newsletter_title",title_8);
        formData.append("appointment_image",imgcard7);
        formData.append("newsletter_image",imgcard8);

        formData.append("first_title", first_title);
        formData.append("second_title", second_title);
        formData.append("third_title", third_title);
        formData.append("four_title", four_title);
        formData.append("fifth_title", fifth_title);
        formData.append("email", Email);


    
        PostApi(`${BaseUrl.baseUrl}admin/home`, formData, userData)
          .then((data) => {
            console.log(data, "minhajansari");
            // setShowToast(true);
            // toast(data.data.message);
            getData()
            
           
            
              
               Swal.fire({
                title: "success",
                text: data.data.message,
                icon: "success",
                confirmButtonColor: "#29BF12",
                timer: 1500,
              });
    
            //   localStorage.setItem("token", data.data.token);
            //   localStorage.setItem("email", data.data.user.email);
            //   localStorage.setItem("fname", data.data.user.first_name);
            //   localStorage.setItem("lname", data.data.user.last_name);
              // localStorage.setItem("lname", data.data.user.last_name);
            //   localStorage.setItem("heightfeet", data.data.user.personal_detail.height_feet);
            //   localStorage.setItem("heightinches", data.data.user.personal_detail.height_inches);
            // //   localStorage.setItem("lname", data.data.data.lname);
            //   localStorage.setItem("id", data.data.data.id);
            //   localStorage.setItem("img", data.data.data.profile);
    
             
    
            //   setloader(false)
            
              // console.log("name", localStorage.getItem("name"));
            //   navigate("/surrogate-application");
          
              
              
              // alert(data.data);
              
        
             
            
          })
          .catch((error) => {
            console.log("mmm", error);
            // setloader(false)
            // setShowToast(true);
            // toast(error.message);
          });
    
        // setShow(!show)
      };
      
  return (
    <>
    {loader ? <Loader fullpage loading /> : null}
    <Header />
    <div className="app-content content">
    <div className="content-wrapper">
      <div className="content-header row">
        <div className="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
          <h3 className="content-header-title mb-0 d-inline-block">Add content</h3>
          <div className="row breadcrumbs-top d-inline-block">
            <div className="breadcrumb-wrapper col-12">
           
            
            </div>
          </div>
        </div>
        <div className="content-header-right col-md-6 col-12">
          <div className="dropdown float-md-right">
            {/* <button className="btn btn-danger dropdown-toggle round btn-glow px-2" id="dropdownBreadcrumbButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</button> */}
            {/* <div className="dropdown-menu" aria-labelledby="dropdownBreadcrumbButton"><a className="dropdown-item" href="#"><i className="la la-calendar-check-o" /> Calender</a>
              <a className="dropdown-item" href="#"><i className="la la-cart-plus" /> Cart</a>
              <a className="dropdown-item" href="#"><i className="la la-life-ring" /> Support</a>
              <div className="dropdown-divider" /><a className="dropdown-item" href="#"><i className="la la-cog" /> Settings</a>
            </div> */}
          </div>
        </div>
      </div>
      <div className="content-body">
      <h1>Phone</h1>
        <textarea className='col-lg-12' style={{height:'200px'}}
           onChange={(e)=>{setphone(e.target.value)}}
           value={phone}
        >
</textarea>

<h1>Address</h1>
        <textarea className='col-lg-12' style={{height:'200px'}}
           onChange={(e)=>{setaddress(e.target.value)}}
           value={address} 
        >
</textarea>

<h1>Logo</h1>
<div className='p-1 border border-dark bg-white'>
        <input  type='file' accept='.jpg, .png, .japack' className='col-lg-12'          
         defaultValue={logo} 
         
         style={{height:'200px'}}
           onChange={(e)=>{setlogo(e.target.files[0])}}/>

</div>
        
<h1>Banner Image</h1>
<div className='p-1 border border-dark bg-white'>
        <input  type='file' accept='.jpg, .png, .japack' className='col-lg-12'          
         defaultValue={Bannerimg} 
         
         style={{height:'200px'}}
           onChange={(e)=>{setBannerimg(e.target.files[0])}}/>

</div>

<h1>Banner Title</h1>
        <textarea className='col-lg-12' style={{height:'200px'}}
           onChange={(e)=>{setfirst_title(e.target.value)}}
           value={first_title}
        
        >

</textarea>

        <h1>Banner</h1>
        <textarea className='col-lg-12' style={{height:'200px'}}
           onChange={(e)=>{setfirst_detail(e.target.value)}}
           value={first_detail}
        
        >

</textarea>


<h1>Second Section Title </h1>
        <textarea className='col-lg-12' style={{height:'200px'}} onChange={(e)=>{setsecond_title(e.target.value)}}
        value={second_title}
        >
        

</textarea>

<h1>Second Section Content
 </h1>
        <textarea className='col-lg-12' style={{height:'200px'}} onChange={(e)=>{setsecond_detail(e.target.value)}}
        value={second_detail}
        >
</textarea>

{/* /////////////// */}
<h1>Third Section First Image</h1>
<div className='p-1 border border-dark bg-white'>
        <input  type='file' accept='.jpg, .png, .japack' className='col-lg-12'          
         defaultValue={imgcard1} 
         
         style={{height:'200px'}}
           onChange={(e)=>{setimgcard1(e.target.files[0])}}/>

</div>

<h1>Third Section First Title </h1>
        <textarea className='col-lg-12' style={{height:'200px'}} onChange={(e)=>{settitle_1(e.target.value)}}
        value={title_1}
        >
</textarea>
<h1>Third Section First Content </h1>
        <textarea className='col-lg-12' style={{height:'200px'}} onChange={(e)=>{setdescription_1(e.target.value)}}
        value={description_1}
        >
</textarea>
 {/* ///////////// */}

 {/* /////////////// */}
<h1>Third Section Second Image</h1>
<div className='p-1 border border-dark bg-white'>
        <input  type='file' accept='.jpg, .png, .japack' className='col-lg-12'          
         defaultValue={imgcard2} 
         
         style={{height:'200px'}}
           onChange={(e)=>{setimgcard2(e.target.files[0])}}/>

</div>

<h1>Third Section Second Title </h1>
        <textarea className='col-lg-12' style={{height:'200px'}} onChange={(e)=>{settitle_2(e.target.value)}}
        value={title_2}
        >
</textarea>
<h1>Third Section Second Content </h1>
        <textarea className='col-lg-12' style={{height:'200px'}} onChange={(e)=>{setdescription_2(e.target.value)}}
        value={description_2}
        >
</textarea>
 {/* ///////////// */}


{/* /////////////// */}
<h1>Third Section Third Image</h1>
<div className='p-1 border border-dark bg-white'>
        <input  type='file' accept='.jpg, .png, .japack' className='col-lg-12'          
         defaultValue={imgcard3} 
         
         style={{height:'200px'}}
           onChange={(e)=>{setimgcard3(e.target.files[0])}}/>

</div>

<h1>Third Section Third Title </h1>
        <textarea className='col-lg-12' style={{height:'200px'}} onChange={(e)=>{settitle_3(e.target.value)}}
        value={title_3}
        >
</textarea>
<h1>Third Section Third  Content </h1>
        <textarea className='col-lg-12' style={{height:'200px'}} onChange={(e)=>{setdescription_3(e.target.value)}}
        value={description_3}
        >
</textarea>
 {/* ///////////// */}

 {/* /////////////// */}
<h1>Third Section Fourth Image</h1>
<div className='p-1 border border-dark bg-white'>
        <input  type='file' accept='.jpg, .png, .japack' className='col-lg-12'          
         defaultValue={imgcard4} 
         
         style={{height:'200px'}}
           onChange={(e)=>{setimgcard4(e.target.files[0])}}/>

</div>

<h1>Third Section Fourth  Title </h1>
        <textarea className='col-lg-12' style={{height:'200px'}} onChange={(e)=>{settitle_4(e.target.value)}}
        value={title_4}
        >
</textarea>
<h1>Third Section Fourth  Content </h1>
        <textarea className='col-lg-12' style={{height:'200px'}} onChange={(e)=>{setdescription_4(e.target.value)}}
        value={description_4}
        >
</textarea>
 {/* ///////////// */}

<h1>Fourth Section Title</h1>
        <textarea className='col-lg-12' style={{height:'200px'}} onChange={(e)=>{setthird_title(e.target.value)}}
         value={third_title}
        >

</textarea>


<h1>Fourth Section Content</h1>
        <textarea className='col-lg-12' style={{height:'200px'}} onChange={(e)=>{setthird_detail(e.target.value)}}
         value={third_detail}
        >

</textarea>


<h1>Fifth Section Title</h1>
        <textarea className='col-lg-12'  style={{height:'200px'}} onChange={(e)=>{setfour_title(e.target.value)}} 
         value={four_title}
        >

</textarea>

<h1>Fifth Section content</h1>
        <textarea className='col-lg-12'  style={{height:'200px'}} onChange={(e)=>{setfour_detail(e.target.value)}} 
         value={four_detail}
        >

</textarea>


<h1>Sixth Section Title</h1>
        <textarea className='col-lg-12' style={{height:'200px'}} onChange={(e)=>{setfifth_title(e.target.value)}}
        
        value={fifth_title}
        >

</textarea>
<h1>Sixth Section Content</h1>
        <textarea className='col-lg-12' style={{height:'200px'}} onChange={(e)=>{setfifth_detail(e.target.value)}}
        
        value={fifth_detail}
        >

</textarea>


<h1>Seventh Section Image</h1>
<div className='p-1 border border-dark bg-white'>
        <input  type='file' accept='.jpg, .png, .japack' className='col-lg-12'          
         defaultValue={imgcard7} 
         
         style={{height:'200px'}}
           onChange={(e)=>{setimgcard7(e.target.files[0])}}/>

</div>
<h1>Seventh Section Title</h1>
        <textarea className='col-lg-12' style={{height:'200px'}} onChange={(e)=>{settitle_7(e.target.value)}}
         value={title_7}
        >

</textarea>


<h1>Seventh Section Content</h1>
        <textarea className='col-lg-12' style={{height:'200px'}} onChange={(e)=>{setdescription_7(e.target.value)}}
         value={description_7}
        >

</textarea>

<h1>Seventh Section Email</h1>
        <textarea className='col-lg-12' style={{height:'200px'}} onChange={(e)=>{setEmail(e.target.value)}}
         value={Email}
        >

</textarea>



<h1>Eighth Section Image</h1>
<div className='p-1 border border-dark bg-white'>
        <input  type='file' accept='.jpg, .png, .japack' className='col-lg-12'          
         defaultValue={imgcard8} 
         
         style={{height:'200px'}}
           onChange={(e)=>{setimgcard8(e.target.files[0])}}/>

</div>

<h1>Eighth Section Title</h1>
        <textarea className='col-lg-12' style={{height:'200px'}} onChange={(e)=>{settitle_8(e.target.value)}}
         value={title_8}
        >

</textarea>


<h1>Eighth Section Content</h1>
        <textarea className='col-lg-12' style={{height:'200px'}} onChange={(e)=>{setdescription_8(e.target.value)}}
         value={description_8}
        >

</textarea>
<button type='button' className='form-control'onClick={onSubmit}>Edit</button>
       
       
       
      </div>
    </div>
  </div>
  <Footer/>
  </>
    
    
  )
}

export default Addhome

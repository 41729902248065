import { useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";

import { useNavigate } from "react-router-dom";
// import AddBox from "@material-ui/icons/AddBox";
// import ArrowDownward from "@material-ui/icons/ArrowDownward";
// import Check from "@material-ui/icons/Check";
// import ChevronLeft from "@material-ui/icons/ChevronLeft";
// import ChevronRight from "@material-ui/icons/ChevronRight";
// import Clear from "@material-ui/icons/Clear";
// import DeleteOutline from "@material-ui/icons/DeleteOutline";
// import Edit from "@material-ui/icons/Edit";
// import FilterList from "@material-ui/icons/FilterList";
// import FirstPage from "@material-ui/icons/FirstPage";
// import LastPage from "@material-ui/icons/LastPage";
// import Remove from "@material-ui/icons/Remove";
// import SaveAlt from "@material-ui/icons/SaveAlt";
// import Search from "@material-ui/icons/Search";
// import ViewColumn from "@material-ui/icons/ViewColumn";

import BaseUrl from "../../baseurl/BaseUrl";
import { Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
// import { Input } from '@material-ui/core';
import { PostApi, } from "../../Helper/helper";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
import { GrHide, GrView } from "react-icons/gr";

const Changepassword = () => {
  const [loader, ] = useState(false);
  const [loder, ] = useState(false);
  const [show, setShow] = useState(false)
  const [show2, setShow2] = useState(false)
  const [show3, setShow3] = useState(false)



  const [oldpassword, setoldpassword] = useState(false);
  const [newpassword, setnewpassword] = useState(false);
  const [confirmpassword, setconfirmpassword] = useState(false);
  const navigate = new useNavigate();
  
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/Login");
    } else {
      // setShow2(true);
    }
    window.scrollTo(0, 0);

    // getData(); // Assuming getData is a function defined outside useEffect
    // getDatas();
    // statusll("pending")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //   const nextpage =(id)=>{
  //     console.log(id, 'll')
  //     navigate(`/parent-details?id=${id}`);

  //   }
  // const tableIcons = {
  //     Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  //     Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  //     Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  //     DeleteOutline: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  //     DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  //     Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  //     Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  //     Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  //     FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  //     LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  //     NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  //     PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  //     ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  //     Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  //     SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  //     ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  //     ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  //   };
  //   const [, setdatas] = useState();
  const [userData] = useState(localStorage.getItem("token"));
//   const getData = () => {
//     setLoader(true);
//     getApiWithToken(`${BaseUrl.baseUrl}admin/about`, "", userData)
//       .then(({ data }) => {
//         //   setdatas(data.home)
// console.log(data.about);
//         setfirst_detail(data.about?.first_detail);
//         setsecond_detail(data.about?.second_detail);
//         setfirst_title(data.about?.first_title);
//         setsecond_title(data.about?.second_title);

       

//         setLoader(false);
//       })
//       .catch((err) => {
//         console.log("err---------", err);
//       });
//   };
//   const [first_detail, setfirst_detail] = useState();
//   const [second_detail, setsecond_detail] = useState();
//   const [first_title, setfirst_title] = useState();
//   const [second_title, setsecond_title] = useState();


  const onSubmit = () => {
    // setloader(true)
    // console.log('rewre============w')
    const formData = new FormData();
    formData.append("current_password", oldpassword);
    formData.append("password", newpassword);
    formData.append("password_confirmation", confirmpassword);

    

    PostApi(`${BaseUrl.baseUrl}admin/change/password`, formData, userData)
      .then((data) => {
        console.log(data, "minhajansari");
        // setShowToast(true);
        // toast(data.data.message);
        // getData();

        Swal.fire({
          title: "success",
          text: data.data.message,
          icon: "success",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });

        //   localStorage.setItem("token", data.data.token);
        //   localStorage.setItem("email", data.data.user.email);
        //   localStorage.setItem("fname", data.data.user.first_name);
        //   localStorage.setItem("lname", data.data.user.last_name);
        // localStorage.setItem("lname", data.data.user.last_name);
        //   localStorage.setItem("heightfeet", data.data.user.personal_detail.height_feet);
        //   localStorage.setItem("heightinches", data.data.user.personal_detail.height_inches);
        // //   localStorage.setItem("lname", data.data.data.lname);
        //   localStorage.setItem("id", data.data.data.id);
        //   localStorage.setItem("img", data.data.data.profile);

        //   setloader(false)

        // console.log("name", localStorage.getItem("name"));
        //   navigate("/surrogate-application");

        // alert(data.data);
      })
      .catch((error) => {
        console.log("mmm", error);

        Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error", // Change icon to "error" for an error message
            confirmButtonColor: "#d33", // Red color typically used for error/danger
            timer: 3000, 
          });
        // setloader(false)
        // setShowToast(true);
        // toast(error.message);
      });

    // setShow(!show)
  };

  return (
    <>
      {loader ? <Loader fullpage loading /> : null}
      <Header />
      <div className="app-content content">
        <div className="content-wrapper">
        
          <div className="content-body">


          <div className="">
            <div className="content-wrapper">
                <div className="content-header row">
                </div>
                <div className="content-body">
                    <section className="flexbox-container">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className="col-md-6 col-10 box-shadow-2 p-0">
                                <div className="card border-grey border-lighten-3 m-0">
                                    <div className="card-header border-0">
                                        <div className="card-title text-center">
                                            <div className="p-1">
                                                <img src="../../../app-assets/images/logo/logos.png" alt="branding logo" style={{width:'100PX'}}/>
                                            </div>
                                        </div>
                                        <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                                            <span>Change Password</span>
                                        </h6>
                                    </div>
                                    <div className="card-content">
                                        <div className="card-body">
                                            <form className="form-horizontal form-simple">
                                            <div className="d-flex fontlar justify-content-between rounded border border-gray mt-2">                       
			<input
      className="pl-2 fontlar w-100"
     onChange={(e)=>setoldpassword(e.target.value)}
				name="password"
				autocomplete="off"
        placeholder="Current Password"
				type={show ? 'text' : 'password'}
        style={{ height: "45px" }}
			/>
			<button style={{border:"none"}} className="pr-1 fontlar" onClick={(e) => {
                e.preventDefault();
                setShow(!show)
                }}>
				{show ? <GrView size={17} /> : <GrHide size={17} />}
			</button>
      </div>   
                                                {/* <fieldset className="form-group position-relative has-icon-left mb-0">
                                                    <input type="password" className="form-control form-control-lg input-lg" id="user-name" placeholder="Current Password" required
                                                     onChange={(e)=>{setoldpassword(e.target.value)}}
                                                    />
                                                    <div className="form-control-position">
                                                        <i className="ft-user" />
                                                    </div>
                                                </fieldset> */}


<div className="d-flex fontlar justify-content-between rounded border border-gray mt-2">                       
			<input
      className="pl-2 fontlar w-100"
     onChange={(e)=>setnewpassword(e.target.value)}
				name="password"
        placeholder="New Password"
				autocomplete="off"
				type={show2 ? 'text' : 'password'}
        style={{ height: "45px" }}
			/>
			<button style={{border:"none"}} className="pr-1 fontlar" onClick={(e) => {
                e.preventDefault();
                setShow2(!show2)
                }}>
				{show2 ? <GrView size={17} /> : <GrHide size={17} />}
			</button>
      </div>  
                                                {/* <fieldset className="form-group position-relative has-icon-left mb-0 mt-2">
                                                    <input type="password" className="form-control form-control-lg input-lg" id="user-name" placeholder="New Password" required
                                                     onChange={(e)=>{setnewpassword(e.target.value)}}
                                                    />
                                                    <div className="form-control-position">
                                                        <i className="ft-user" />
                                                    </div>
                                                </fieldset> */}


                                     <div className="d-flex fontlar justify-content-between rounded border border-gray mt-2">                       
			<input
      className="pl-2 fontlar w-100"
     onChange={(e)=>setconfirmpassword(e.target.value)}
				name="password"
				autocomplete="off"
				type={show3 ? 'text' : 'password'}
        style={{ height: "45px" }}
        placeholder="Confirm Password"
			/>
			<button style={{border:"none"}} className="pr-1 fontlar" onClick={(e) => {
                e.preventDefault();
                setShow3(!show3)
                }}>
				{show3 ? <GrView size={17} /> : <GrHide size={17} />}
			</button>
      </div>           
                                                
                                                <div className="form-group row">
                                                    {/* <div className="col-md-6 col-12 text-center text-md-left">
                                                        <fieldset>
                                                            <input type="checkbox" id="remember-me" className="chk-remember" />
                                                            <label htmlFor="remember-me"> Remember Me</label>
                                                        </fieldset>
                                                    </div> */}
                                                    {/* <div className="col-md-6 col-12 text-center text-md-right"><a href="recover-password.html" className="card-link">Forgot Password?</a></div> */}
                                                </div>
                                                <button type="button" className="btn btn-info btn-lg btn-block"  onClick={onSubmit}>
                                                {loder !== false? 
                      <Spinner animation="border"  />:'Change Password'  
                      
                     }
                     {/* <i className="ft-unlock" />  */}
                                                
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        {/* <div className>
                                            <p className="float-sm-left text-center m-0"><a href="recover-password.html" className="card-link">Recover password</a></p>
                                            <p className="float-sm-right text-center m-0">New to Moden Admin? <a href="register-simple.html" className="card-link">Sign Up</a></p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
             
          {/* <h1>First Section title</h1>
            <textarea
              className="col-lg-12"
              style={{ height: "200px" }}
              onChange={(e) => {
                setfirst_title(e.target.value);
              }}
              value={first_title}
            ></textarea>

            <h1>First_detail</h1>
            <textarea
              className="col-lg-12"
              style={{ height: "200px" }}
              onChange={(e) => {
                setfirst_detail(e.target.value);
              }}
              value={first_detail}
            ></textarea>

<h1>Second Section Title</h1>
            <textarea
              className="col-lg-12"
              style={{ height: "200px" }}
              onChange={(e) => {
                setsecond_title(e.target.value);
              }}
              value={second_title}
            ></textarea>

            <h1>second_detail</h1>
            <textarea
              className="col-lg-12"
              style={{ height: "200px" }}
              onChange={(e) => {
                setsecond_detail(e.target.value);
              }}
              value={second_detail}
            ></textarea>
            <button type="button" className="form-control" onClick={onSubmit}>
              Edit
            </button>
           */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Changepassword;

import {useEffect, useState} from 'react'
import Header from '../../layout/Header'
import Footer from '../../layout/Footer'
import MaterialTable from "material-table";
// import AddBox from "@material-ui/icons/AddBox";
// import ArrowDownward from "@material-ui/icons/ArrowDownward";
// import Check from "@material-ui/icons/Check";
// import ChevronLeft from "@material-ui/icons/ChevronLeft";
// import ChevronRight from "@material-ui/icons/ChevronRight";
// import Clear from "@material-ui/icons/Clear";
// import DeleteOutline from "@material-ui/icons/DeleteOutline";
// import Edit from "@material-ui/icons/Edit";
// import FilterList from "@material-ui/icons/FilterList";
// import FirstPage from "@material-ui/icons/FirstPage";
// import LastPage from "@material-ui/icons/LastPage";
// import Remove from "@material-ui/icons/Remove";
// import SaveAlt from "@material-ui/icons/SaveAlt";
// import Search from "@material-ui/icons/Search";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
// import ViewColumn from "@material-ui/icons/ViewColumn";
import { useNavigate,   } from 'react-router-dom'
import { DeleteApi, getApiWithToken } from "../../Helper/helper";
import BaseUrl from '../../baseurl/BaseUrl';
import { Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import Swal from 'sweetalert2';
import { DeleteOutline } from '@material-ui/icons';


const Surrogate  =()=> {
    const navigate = new useNavigate();
    const [loader, setLoader] = useState(false);
    useEffect(() => {
      if (!localStorage.getItem("token")) {
        navigate("/Login");
      } else {
        // setShow2(true);
      }
      window.scrollTo(0, 0);
    
      getData(); // Assuming getData is a function defined outside useEffect
      // getDatas();
      // statusll("pending")
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
      // const [searchParams] = useSearchParams();
      const [datas, setdatas] = useState([]);
      const [userData,] = useState(localStorage.getItem("token"));
        const getData = () => {
          setLoader(true)
          getApiWithToken(`${BaseUrl.baseUrl}admin/surrogate`, "", userData)
            .then(({ data }) => {
              console.log("minhaj", data?.users);
              setdatas(data?.users)
               
              // setName(data.name);
              // setEmail(data.email);
              // setProfileImage(data.picture);
              setLoader(false);
            })
            .catch((err) => {
              console.log("err---------", err);
            });
        };

        const nextpage =(id)=>{
          console.log(id, 'll')
          navigate(`/surrogate-details?id=${id}`);
      
      
      
      
      
        }
    // const tableIcons = {
    //     Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    //     Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    //     Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    //     DeleteOutline: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    //     DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    //     Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    //     Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    //     Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    //     FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    //     LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    //     NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    //     PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    //     ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    //     Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    //     SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    //     ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    //     ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    //   };
    const DeleteData = (e) => {
      setLoader(true)

      // console.log(e);
        DeleteApi(`${BaseUrl.baseUrl}admin/surrogate/${e}`,"",userData)
        .then((response) => {
          console.log(response.status);
          if (response.status==="success") {
            setLoader(false)
            Swal.fire({
              title: "success",
              text: "surrogate Deleted successfully",
              icon: "success",
              confirmButtonColor: "#29BF12",
              timer: 2000,
            });
          }
          console.log("delete");
          getData()

        })
        .catch(() => {
          Swal.fire({
            title: "Fail",
            text: "Something Went Wrong",
            icon: "error",
            confirmButtonColor: "#29BF12",
            timer: 2000,
          });        });
    };

  return (
    <>
       {loader ? <Loader fullpage loading /> : null}
    <Header />
    <div className="app-content content">
    <div className="content-wrapper">
      <div className="content-header row">
        <div className="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
          <h3 className="content-header-title mb-0 d-inline-block">Surrogate Application</h3>
          <div className="row breadcrumbs-top d-inline-block">
            <div className="breadcrumb-wrapper col-12">
           
            
            </div>
          </div>
        </div>
        <div className="content-header-right col-md-6 col-12">
          <div className="dropdown float-md-right">
            {/* <button className="btn btn-danger dropdown-toggle round btn-glow px-2" id="dropdownBreadcrumbButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</button> */}
            {/* <div className="dropdown-menu" aria-labelledby="dropdownBreadcrumbButton"><a className="dropdown-item" href="#"><i className="la la-calendar-check-o" /> Calender</a>
              <a className="dropdown-item" href="#"><i className="la la-cart-plus" /> Cart</a>
              <a className="dropdown-item" href="#"><i className="la la-life-ring" /> Support</a>
              <div className="dropdown-divider" /><a className="dropdown-item" href="#"><i className="la la-cog" /> Settings</a>
            </div> */}
          </div>
        </div>
      </div>
      <div className="content-body">
        {/* Description */}
        <section id="description" className="card">
        <MaterialTable
    //   icons={tableIcons}
                  title="List"
                  columns={[
                    { title: "First Name", field: "first_name" },
                    { title: "last Name", field: "last_name" },
                    { title: "Email", field: "email" },
                    { title: "Phone Number", field: "disregarded_entity" },
                    // { title: "Health information", field: "health_information" },

                    // { title: "Trade Name", field: "trade_name" },
                    
                  ]}
                  data={datas}
                  options={{
                    addRowPosition: "first",
                  }}
                  actions={[
                    {
                      icon: RemoveRedEyeIcon ,
                      tooltip: "ViewColumn",
                      onClick: (event, rowData) => {
                        nextpage(rowData.id);
                      },
                    },
                    (rowData) => ({
                      icon: DeleteOutline,
                      tooltip: "Delete User",
                      onClick: (id, rowData) => {
                        DeleteData(rowData?.id)
                        // delData(rowData);
                      },
                    }),
                  ]}
                />
          
          
        </section>
        {/*/ Description */}
        {/* Image grid */}
       
        {/*/ Image grid */}
        {/* Video grid */}
       
        {/* Video grid */}
      </div>
    </div>
  </div>
  <Footer/>
  </>
    
    
  )
}

export default Surrogate 

import { useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";

import { useNavigate } from "react-router-dom";
// import AddBox from "@material-ui/icons/AddBox";
// import ArrowDownward from "@material-ui/icons/ArrowDownward";
// import Check from "@material-ui/icons/Check";
// import ChevronLeft from "@material-ui/icons/ChevronLeft";
// import ChevronRight from "@material-ui/icons/ChevronRight";
// import Clear from "@material-ui/icons/Clear";
// import DeleteOutline from "@material-ui/icons/DeleteOutline";
// import Edit from "@material-ui/icons/Edit";
// import FilterList from "@material-ui/icons/FilterList";
// import FirstPage from "@material-ui/icons/FirstPage";
// import LastPage from "@material-ui/icons/LastPage";
// import Remove from "@material-ui/icons/Remove";
// import SaveAlt from "@material-ui/icons/SaveAlt";
// import Search from "@material-ui/icons/Search";
// import ViewColumn from "@material-ui/icons/ViewColumn";

import BaseUrl from "../../baseurl/BaseUrl";
import { Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
// import { Input } from '@material-ui/core';
import { PostApi, getApiWithToken } from "../../Helper/helper";
import Swal from "sweetalert2";

const AddAdout = () => {
  const [loader, setLoader] = useState(false);
  const navigate = new useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/Login");
    } else {
      // setShow2(true);
    }
    window.scrollTo(0, 0);

    getData(); // Assuming getData is a function defined outside useEffect
    // getDatas();
    // statusll("pending")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //   const nextpage =(id)=>{
  //     console.log(id, 'll')
  //     navigate(`/parent-details?id=${id}`);

  //   }
  // const tableIcons = {
  //     Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  //     Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  //     Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  //     DeleteOutline: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  //     DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  //     Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  //     Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  //     Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  //     FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  //     LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  //     NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  //     PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  //     ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  //     Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  //     SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  //     ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  //     ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  //   };
  //   const [, setdatas] = useState();
  const [userData] = useState(localStorage.getItem("token"));
  const getData = () => {
    setLoader(true);
    getApiWithToken(`${BaseUrl.baseUrl}admin/about`, "", userData)
      .then(({ data }) => {
        //   setdatas(data.home)
console.log(data.about);
        setfirst_detail(data.about?.first_detail);
        setsecond_detail(data.about?.second_detail);
        setfirst_title(data.about?.first_title);
        setsecond_title(data.about?.second_title);

       

        setLoader(false);
      })
      .catch((err) => {
        console.log("err---------", err);
      });
  };
  const [first_detail, setfirst_detail] = useState();
  const [second_detail, setsecond_detail] = useState();
  const [first_title, setfirst_title] = useState();
  const [second_title, setsecond_title] = useState();


  const onSubmit = () => {
    // setloader(true)
    // console.log('rewre============w')
    const formData = new FormData();
    formData.append("first_detail", first_detail);
    formData.append("second_detail", second_detail);
    formData.append("first_title", first_title);
    formData.append("second_title", second_title);

    

    PostApi(`${BaseUrl.baseUrl}admin/about`, formData, userData)
      .then((data) => {
        console.log(data, "minhajansari");
        // setShowToast(true);
        // toast(data.data.message);
        getData();

        Swal.fire({
          title: "success",
          text: data.data.message,
          icon: "success",
          confirmButtonColor: "#29BF12",
          timer: 1500,
        });

        //   localStorage.setItem("token", data.data.token);
        //   localStorage.setItem("email", data.data.user.email);
        //   localStorage.setItem("fname", data.data.user.first_name);
        //   localStorage.setItem("lname", data.data.user.last_name);
        // localStorage.setItem("lname", data.data.user.last_name);
        //   localStorage.setItem("heightfeet", data.data.user.personal_detail.height_feet);
        //   localStorage.setItem("heightinches", data.data.user.personal_detail.height_inches);
        // //   localStorage.setItem("lname", data.data.data.lname);
        //   localStorage.setItem("id", data.data.data.id);
        //   localStorage.setItem("img", data.data.data.profile);

        //   setloader(false)

        // console.log("name", localStorage.getItem("name"));
        //   navigate("/surrogate-application");

        // alert(data.data);
      })
      .catch((error) => {
        console.log("mmm", error);
        // setloader(false)
        // setShowToast(true);
        // toast(error.message);
      });

    // setShow(!show)
  };

  return (
    <>
      {loader ? <Loader fullpage loading /> : null}
      <Header />
      <div className="app-content content">
        <div className="content-wrapper">
          <div className="content-header row">
            <div className="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
              <h3 className="content-header-title mb-0 d-inline-block">
                Add content
              </h3>
              <div className="row breadcrumbs-top d-inline-block">
                <div className="breadcrumb-wrapper col-12"></div>
              </div>
            </div>
            <div className="content-header-right col-md-6 col-12">
              <div className="dropdown float-md-right">
                {/* <button className="btn btn-danger dropdown-toggle round btn-glow px-2" id="dropdownBreadcrumbButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</button> */}
                {/* <div className="dropdown-menu" aria-labelledby="dropdownBreadcrumbButton"><a className="dropdown-item" href="#"><i className="la la-calendar-check-o" /> Calender</a>
              <a className="dropdown-item" href="#"><i className="la la-cart-plus" /> Cart</a>
              <a className="dropdown-item" href="#"><i className="la la-life-ring" /> Support</a>
              <div className="dropdown-divider" /><a className="dropdown-item" href="#"><i className="la la-cog" /> Settings</a>
            </div> */}
              </div>
            </div>
          </div>
          <div className="content-body">
             
          <h1>First Section title</h1>
            <textarea
              className="col-lg-12"
              style={{ height: "200px" }}
              onChange={(e) => {
                setfirst_title(e.target.value);
              }}
              value={first_title}
            ></textarea>

            <h1>First_detail</h1>
            <textarea
              className="col-lg-12"
              style={{ height: "200px" }}
              onChange={(e) => {
                setfirst_detail(e.target.value);
              }}
              value={first_detail}
            ></textarea>

<h1>Second Section Title</h1>
            <textarea
              className="col-lg-12"
              style={{ height: "200px" }}
              onChange={(e) => {
                setsecond_title(e.target.value);
              }}
              value={second_title}
            ></textarea>

            <h1>second_detail</h1>
            <textarea
              className="col-lg-12"
              style={{ height: "200px" }}
              onChange={(e) => {
                setsecond_detail(e.target.value);
              }}
              value={second_detail}
            ></textarea>
            <button type="button" className="form-control" onClick={onSubmit}>
              Edit
            </button>
          
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddAdout;
